textarea {
  resize: none;
}

.col {
  height: 500px;
}

.toast {
  max-width: 500px;
}

.input-gun-button {
  margin: 2px;
}

.horizontal-scrolling {
  overflow-y: hidden;
  overflow-x: auto;
}

.chart-area {
  height: 500px;
  min-width: 800px;
}

.setting-buttons {
  margin: 2px;
} 

#left-bar {
  background-color: white;
  max-width: 400px;
}

#stats-table {
  margin-top: 12px;
  min-width: 1200px;
}

#damage-chart-area {
  height: 500px;
  padding-top: 10px;
}

#right-bar {
  min-width: 0;
  background-color: rgb(255, 255, 255);
}

#gun-list {
  background-color: rgb(255, 255, 255);
}

#sort-list {
  background-color: coral;
}

#del-all {
  margin-top: 10px;
  margin-bottom: 10px;
}

#settings-area {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

#input-gun-buttons {
  height: auto;
  padding-top: 10px;
  max-height: 800px;
  overflow-y: auto;
}

#share-url-bar {
  margin-top: 10px;
}